<template>
	<div class="content">
		<div class="releaseDetail">
			
			<div class="already-release">
				<h3>已发布平台</h3>
				<h4 v-show="!articleData.releasedDriver || articleData['releasedDriver'] === []">暂无</h4>
				<div v-for="platform in platformList" v-show="articleData.releasedDriver && articleData['releasedDriver'] !== []" :key="platform.platformName">
					<h3>{{ platform.platformName }}</h3>
					<el-checkbox-group v-model="alreadyReleaseDriver">
						<el-checkbox v-for="driverId in articleData['releasedDriver']" :key="driverId"
						             v-if="driverList[platform.platform].find((driver) => {return driver._id === driverId})"
						             checked>
							{{ driverList[platform.platform].find((driver) => {return driver._id === driverId}).account }}/{{ driverList[platform.platform].find((driver) => {return driver._id === driverId}).remark }}
						</el-checkbox>
					</el-checkbox-group>
				</div>
			</div>

			<div class="release">
				<h2>已绑定账户</h2>

          <div v-for="platform in platformList" :key="platform.platformName">
            <h3>{{ platform.platformName }}</h3>
            <el-checkbox-group v-model="selectDriver" @change="releaseAccountChange">
            <el-checkbox  v-for="item in driverList[platform.platform]" :key="item._id"
                          v-loading="loadingMap[platform.platform][item._id]" :label="item._id"
            >{{ item.account }}/{{item.remark}}</el-checkbox>
            </el-checkbox-group>
          </div>

				<div class="release-button">
					<el-button size="medium" type="primary" @click="release">执行发布</el-button>
				</div>
			</div>
		</div>

		<div>
			<div style="text-align: right;margin-top:1rem">
				<el-button size="small" type="primary" @click="gotoEdit">进入修改</el-button>
			</div>
			<div class="result">
				<h2>{{ title }}</h2>
				<img class="img-result" v-show="articleData.autoGenImg!==false" :src="imgUrl"/>
        <div>
          <img v-if="!isVideo(img)" class="local-img" :src="img" v-for="img in selectLocalImgList" :key="img"/>
          <div v-if="isVideo(img)" v-for="img in selectLocalImgList" :key="img">
            <video width="640" height="480" controls class="video">
              <source :src="img" type="video/mp4">
            </video>
          </div>
        </div>
				<el-input el-input type="textarea" autosize class="article-content" :value="content" v-show="!videoMode" @change=""></el-input>
			</div>
		</div>
	</div>
</template>

<script>

import {isPcClient, isVideo} from "@/utils/utils";

export default {
	data() {
		return {
			title: '',
			content: '',
			imgUrl: '',
			articleId:0,
			alreadyReleaseDriver:{},
			
			selectDriver:[],
			driverList:{},
      driverMap: {},
			platformList:[],
			
			loadingMap: {},
			releaseAble: true,
			
			articleData: {
				releasedDriver:[]
			},
      selectLocalImgList: [],
      videoMode: false,

      driverListInArticle: []
		};
	},
	
	components: {},
	
	computed: {},
	
	onShow() {
	},
	
	mounted() {
		this.initArticle().then(res => {
			this.initDriver()
		})
	},
	
	watch: {
	},
	
	methods: {
		releaseAccountChange() {
			this.$forceUpdate()
		},
		async release() {
      if (!isPcClient()) {
        this.$message.error('请在PC客户端使用此功能')
        return
      }

      if (!this.releaseAble) {
        this.$message.error('发布进行中, 请等到完成, 并且不要退出客户端程序')
        return
      }

      for (const driverId of this.selectDriver) {
        let driverData = this.driverMap[driverId]
        this.$message.success('正在发布到 ' + driverData.platformName + ': ' + driverData.account + '/' + driverData.remark)

        this.loadingMap[driverData.platform][driverData._id] = true
        this.releaseAble = false

        await pywebview.api.dispatchRelease(driverData, this.articleData).then(res => {
          if (res === 'success') {
            //记录已发布成功的平台
            this.$http.post('/client/gen/release', {articleId: this.articleData._id, driverId: driverId}).then(res => {
              if (res.code === 0) {
                this.$message.success('发布到' + driverData.platformName + '成功')
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error(res)
          }
        }).finally(() => {
          this.loadingMap[driverData.platform][driverData._id] = false
          this.releaseAble = true
          this.$forceUpdate()
        })
      }
    },
		
		gotoEdit() {
			this.$router.push({path:'/generate/generate', query:{id: this.articleId}}).then(() => {
				// 获取指定路由对象
				try {
					let matchedRoute = this.$route.matched.find(route => route.path === '/generate/generate');
					if (matchedRoute.instances.default) matchedRoute.instances.default.initEdit()
				} catch (e) {
					console.log(e)
				}
			})
		},
		
		initDriver() {
			this.$http.post("/client/driver/getDriverList", {pageNum:0, pageSize:1000, status:'active'}).then((res) => {
				res.data.list.forEach((item) => {
					if (!item) {
						return
					}
					if ((typeof this.driverList[item.platform]) === 'undefined') {

						this.driverList[item.platform] = []
						this.loadingMap[item.platform] = {}
						this.platformList.push({'platformName':item.platformName, 'platform':item.platform})
					}
					this.loadingMap[item.platform][item._id] = false
					this.driverList[item.platform].push(item)
          this.driverMap[item._id] = item
				})
			})
		},
		initArticle() {
      this.videoMode = false;

			return this.$http.post("/client/gen/getArticleList", {id: parseInt(this.$route.query._id), pageNum:0, pageSize:1, fullContent:true}).then((res) => {
				let article = res.data.list[0]
				this.title = article.title
				this.content = article.content
				this.articleData = article
				this.imgUrl = this.articleData.imgUrlAll
				this.articleId = article._id
        this.selectLocalImgList = this.articleData?.selectLocalImgList || []
        this.driverListInArticle = this.articleData?.driverList || {}
        this.selectDriver = this.articleData?.driverList || []
        if (this.selectLocalImgList.length > 0 && isPcClient()) {
          console.log('多图模式')
          let imgPrefix = this.selectLocalImgList[0].split(',')[0]
          pywebview.api.getFilePrefix(imgPrefix).then(res => {
            this.selectLocalImgList.forEach((item, key) => {
              let tail = item.split(',')[1]
              this.selectLocalImgList[key] = res + tail

              console.log(tail)
              if (isVideo(tail)) {
                this.videoMode = true
              }
            })
            this.$forceUpdate()
          })
        }
			})
		},
    isVideo(tail) {
      return isVideo(tail)
    },
	},
};
</script>
<style lang="scss" scoped>
.content {
	padding: 2rem 1rem;
}
.result {
	max-width: 640px;
	margin: 2rem auto 4rem auto;
	text-align: center;
	h2 {
		margin: 1rem 0 2rem 0;
		text-align: center;
	}
	img {
		max-width: 80%;
		margin: 0 auto;
    border-radius: 10px;
	}
	.article-content {
    width: 450px;
		margin-top: 2rem;
		text-align: left;
    min-height: 400px;
	}
  .local-img {
    width: 130px;
    margin: 10px;
    border-radius: 10px;
  }
}
.releaseDetail {
	border: solid 1px #eee;
	padding: 1rem;
	border-radius: 1rem;
	
	.already-release {
		h3 {
			margin-bottom: 1rem;
		}
		margin-bottom: 1rem;
		border-bottom: solid 1px #eee;
		padding-bottom: 1rem;
	}
	.release-button {
		margin-top: 2rem
	}
	
	.release {
		h3 {
			line-height: 2rem;
			margin-top:1rem
		}
	}
}


</style>

<style lang="scss" scoped>
::v-deep .el-checkbox__input.is-disabled + span.el-checkbox__label {
	color: #5f93fa !important;
}
</style>